import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Maintenance from './maintenance/Container'

const Container = () => {
  return (
    <>
      <Switch>
        <Route path='/' component={ Maintenance } />
        <Route component={ Maintenance } />
      </Switch>
    </>
  )
}

export default Container
