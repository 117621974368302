import React, { useEffect } from 'react'

const Maintenance: React.FC = () => {
  useEffect(() => {}, [])

  return (
    <div className='body align-center-center'>
      <div className='text'>
        <img className='logo' src={ require('../../assets/images/fairplay-logo-gold.png') } />
        <br />
        <p>
          If you have questions regarding your account or withdrawal requests please contact Customer Service at:
          <br />
          <a href='mailto:support@fairplaycasino.com' target='_black'>
            support@fairplaycasino.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default Maintenance
