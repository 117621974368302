import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Maintenance from './Maintenance'

const Application = () => {
  return (
    <React.Suspense fallback='loading'>
      <Router>
        <Maintenance />
      </Router>
    </React.Suspense>
  )
}

export default Application
